<template>
	<div class="p-grid">
		<div class="p-col-8">
			<h1>Price List import</h1>
		</div>
	</div>
	<div class="p-grid">
		<div class="card" v-if="processing">
			<p>Processing export ...</p>
		</div>
		<div class="card p-col-12" v-if="!processing">
			<div class="p-grid">
				<div class="p-col-12 ">
					<Checkbox id="checkOption1" name="option" value="kod" selected v-model="selected" />
					<label for="checkOption1">ABRA Code</label>
				</div>
				<div class="p-col-12">
					<Checkbox id="checkOption2" name="option" value="eanKod" selected v-model="selected" />
					<label for="checkOption1">EAN</label>
				</div>
				<div class="p-col-12">
					<Checkbox id="checkOption3" name="option" value="otherEans" v-model="selected" />
					<label for="checkOption1">Other EANs</label>
				</div>
				<div class="p-col-12">
					<Checkbox id="checkOption4" name="option" value="nazev" selected v-model="selected" />
					<label for="checkOption1">Name</label>
				</div>
				<div class="p-col-12">
					<Checkbox id="checkOption5" name="option" value="vyrobce" v-model="selected" />
					<label for="checkOption1">Manufacturer</label>
				</div>
				<div class="p-col-12">
					<Checkbox id="checkOption6" name="option" value="sorting" v-model="selected" />
					<label for="checkOption1">Zatřízení</label>
				</div>
				<div class="p-col-12">
					<Checkbox id="checkOption7" name="option" value="velikost" v-model="selected" />
					<label for="checkOption1">Velikost</label>
				</div>
				<div class="p-col-12">
					<Checkbox id="checkOption8" name="option" value="baleni" v-model="selected" />
					<label for="checkOption1">Balení</label>
				</div>
			</div>
			<div class="p-grid">
				<div class="p-col-12">
					<!-- <Button
						class="p-button-success p-mr-2"
						icon="pi pi-times"
						label="(Re) Load"
						@click="load()"></Button> -->
					<Button
						class="p-button-success p-mr-2"
						icon="pi pi-download"
						label="Export"
						@click="save()"></Button>
					<!-- <Button
						class="p-button-success p-mr-2"
						icon="pi pi-times"
						label="Show"
						@click="show()"></Button>
					<Button
						class="p-button-success p-mr-2"
						icon="pi pi-times"
						label="Hide"
						@click="hide()"></Button> -->
				</div>
			</div>
		</div>

		<!-- Table -->
		<!-- <div class="card">
			<div class="p-grid">
				<div class="p-col-12 p-md-4">
					<Checkbox id="checkOption1" name="option" value="Chicago" v-model="checkboxValue" />
					<label for="checkOption1">EAN</label>
				</div>
			</div>
		</div> -->
	</div>

</template>

<script>

import wRules from '../service/xlsxParser/parseInRules/productCatalogParseRules.js'
import FlexiAPI from '../service/flexiAPI/flexiAPI.js';
import XLSX from 'xlsx'

function extractEan(arr, idx) {
	if (!arr || arr.length <= idx) {
		return null
	}
	return arr[idx]
}

export default {
	data() {
		return {
			data: {
				cenik: null
			},
			displayed: false,
			processing: false,
			selected: ['kod', 'eanKod', 'otherEans', 'nazev'],
			labels: {
				kod: 'ABRA Kód',
				eanKod: 'EAN',
				nazev: 'Název',
				skupZboz: 'Skupina',
				typ: 'Typ',
				rada: 'Řada',
				vyrobce: 'Výrobce',
				velikost: 'Velikost',
				velikost_mj: 'Mj',
				bqt: 'V balení',
				lqt: 'Ve vrstvě',
				pqt: 'Na paletě',
				ean1: 'EAN1',
				ean2: 'EAN2',
				ean3: 'EAN3',
				ean4: 'EAN4',
				ean5: 'EAN5'
			},
			groups: {
				velikost: ['velikost', 'velikost_mj'],
				baleni: ['lqt', 'bqt', 'pqt'],
				sorting: ['typ', 'skupZboz', 'rada'],
				otherEans: ['ean1', 'ean2', 'ean3', 'ean4', 'ean5']
			},
			resolvers: {
				ean1: (row) => extractEan(row.otherEans, 0),
				ean2: (row) => extractEan(row.otherEans, 1),
				ean3: (row) => extractEan(row.otherEans, 2),
				ean4: (row) => extractEan(row.otherEans, 3),
				ean5: (row) => extractEan(row.otherEans, 4)
			}
		}
	},
	computed: {
		fields() {
			const fields = []
			this.selected.forEach(sel => {
				if (this.groups[sel]) {
					fields.push(...this.groups[sel])
					return
				}
				fields.push(sel)
			})
			return fields
		}
	},
	methods: {
		async load() {
			this.data.cenik = await FlexiAPI.cenik.get()
		},
		async save() {
			this.processing = true;

			await this.load()

			const wb = XLSX.utils.book_new()
			const cenikWs = XLSX.utils.aoa_to_sheet([
				this.fields.map(key => this.labels[key] || key),
				...this.data.cenik.map(itm => this.fields.map(key => {
					let ret = null
					if (typeof this.resolvers[key] === 'function') {
						ret = this.resolvers[key](itm)
					} else {
						ret = itm[key]
					}
					if (ret !== null && ret !== undefined && typeof ret.toString === 'function') {
						ret = ret.toString()
					}
					return ret
				}))
			])
			XLSX.utils.book_append_sheet(wb, cenikWs, "Ceník")
			const date = (new Date()).toISOString().replace('T', '_').replace(':','-').replace(':', '-').substring(0,19)
			XLSX.writeFile(wb, `${date}_cenik.xlsx`);

			this.processing = false;
		},
		show() {
			this.displayed = true
		},
		clear() {

		}
	},
	components: {

	}
}
</script>

<style scoped>

a.p-button {
	cursor: pointer;
}

h1 {
	font-size: 2rem;
}

label {
	padding-left: 1rem;
}

</style>
